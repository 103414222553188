<script>
import Layout from "../../layouts/horizontal";
import { mapGetters, mapActions, mapMutations } from 'vuex';
import PageHeader from "@/components/page-header";
import {Chart} from 'highcharts-vue'
import Runtime from './runtime.vue';
import TaxLossHarvest from './tax-loss-harvest.vue';

export default {
  components: {
    Layout,
    PageHeader,
     Highcharts: Chart,
    Runtime,
    TaxLossHarvest
  },
  created() {
    this.setItemId(this.id);
    this.loadItem();
      
    this.init();
  },
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      breadcrumbs: [
      ],
      userSettings: null,
      userSettingsSaving: false,
      executionSettings: null,
      executionSettingsSaving: false,
      connecting: false,
      gettingBrokerPositions: false,
      activeTab: 0,

      parametersFormId: null,
      parametersFormOutsideClickConfig: {
        handler: this.hideParamtersForm,
        middleware: this.middleware,
        events: ["click"],
      },
      parametersData: {
        price: null,
        ticksAway: 0,
        time: null
      },

      cloneOrdeFormId: null,
      cloneOrderFormOutsideClickConfig: {
        handler: this.hideCloneForm,
        middleware: this.middleware,
        events: ["click"]
      }
    };
  },
  computed:{
    ...mapGetters('execution', {
     // isBackendAlive: 'isBackendAlive',
      item: 'item',
      loading: 'loading',
      interactiveBrokers: 'interactiveBrokers'
    }),
    allExecutionSettingsSelected: {
      get() {
        const res = this.executionSettings.find(x => !x.selected) ? false : true;
        return res ;
      },
      set(v) {
        if(v) {
          this.executionSettings.forEach(x => x.selected = true);
        } else {
          this.executionSettings.forEach(x => x.selected = false);
        }
      }
    },
    allOrderTypes() {
      const allTypes = {};
      this.executionSettings.forEach(x => {
        x.orderTypes.forEach(o => {
          allTypes[o.orderType] = null;
        })
      })
     
      const keys = Object.keys(allTypes).filter(x => x !== 'Market');
      //keys.sort((a, b) => a[0] - b[0])
      keys.sort(function(a, b) {
        var nameA = a.toUpperCase();
        var nameB = b.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      console.log('allTypes', keys);
      return keys;
    },
    
    title() {
      return this.item.name || 'Loading...'
    },
    positions(){
      return this.item.positions;
    },
    reBalance(){
      return this.item.reBalance;
    },
    weights(){
      return this.item.weights;
    },
    transactions(){
      return this.item.transactions;
    },
    chartOptions() {
      if(!this.item.chartData && this.item.chartData.length > 0)
        return null;

      const { chartData } = this.item;  
      let seriesOptions = [
         {
          name: this.item.name,
          label: this.item.name,
          data: chartData.map(x => [x.timeStamp, x.value]),
          color: "#7d096d",
          lineWidth: 1
        }
      ];
      
      let opts = {
        credits:{
           enabled: false
        },
        legend:{
          enabled: true
        },
        exporting: {
          buttons: {
              contextButton: {
                  menuItems: [
                  "viewFullscreen", 
                  "printChart", 
                  "separator", 
                  "downloadPNG", 
                  "downloadJPEG", 
                  "downloadPDF", 
                  "downloadSVG",
                  "separator",
                  "downloadXLS",
                  "downloadCSV",
                  "viewData" ]
              }
          }
        },
        rangeSelector: {
            selected: 5
        },
        xAxis: {
          type: 'datetime',
        
          labels: {
              
          },
        
        },
        yAxis: {
          type: this.mainChartYAxisLogarithmicType ? 'logarithmic' : null,
          labels: {
            formatter: function () {
              return (this.value > 0 ? '' : '') + this.value;
            }
          },
          plotLines: [{
            value: 0,
              width: 2,
              color: 'silver'
          }]
        },
        plotOptions: {
          series: {
            showInNavigator: true
          }
        },
        tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
          valueDecimals: 2,
          xDateFormat:  '%d/%m/%Y'
        },
      
        series: seriesOptions
      };
     
      return opts;
    },
    reBalanceCloseoutTrades:
    {
      get: function() {
        return this.item.reBalanceCloseoutTrades || false;
      },
      set: function(newValue) {

        this.changeReBalanceCloseoutTrades(newValue);
      }
    },
    country: {
      get: function() {
        return this.item.country || 'USD';
      },
      set: function(newValue) {

        this.changeCountry(newValue);
      }
    },
    taxLossHarvestTrades:
    {
      get: function() {
        return this.item.taxLossHarvestTrades || false;
      },
      set: function(newValue) {

        this.changeTaxLossHarvestTrades(newValue);
      }
    },
    currencyName:
    {
      get: function() {
        return this.item.currencyName || 'USD';
      },
      set: function(newValue) {
        this.changeCurrency({ currencyName: newValue});
      }
    },
    connected () {
      return this.interactiveBrokers.connected;
    },
  },
  watch: {
    // isBackendAlive: {
    //   handler: function () {

    //     if(this.isBackendAlive) {
    //       this.loadItem();
    //     }
    //   }
    // },
    item: {
      handler(val) {
        this.userSettings = JSON.parse(JSON.stringify(val.userSettings));
        if(!this.executionSettings) {
          const o = JSON.parse(JSON.stringify(val.executionSettings));
          if(o) {
            o.forEach(x => x.selected = true);
         
            o.forEach(x => {
              x.orderTypes.forEach(a => {
                if(!a.orderParameters) {
                  if(this.userSettings.defaultOrderType === a.orderType) {
                    a.orderParameters = this.userSettings.defaultOrderSettings;
                  }
                }
              });
            });
          }
          this.executionSettings = o;
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations('execution', {
      setItemId: 'SET_ITEM_ID'
    }),
    ...mapActions('execution', {
      initRuntime: 'initRuntime',
      loadItem: 'loadItem',
      saveUserSettings: 'saveUserSettings',
      saveExecutionSettings: 'saveExecutionSettings',
      loadUserSettings: 'loadUserSettings',
      loadTransactions: 'loadTransactions',
      loadExecutionSettings: 'loadExecutionSettings',
      loadPositions:'loadPositions',
      changeCurrency: 'changeCurrency',
      changeReBalanceCloseoutTrades : 'changeReBalanceCloseoutTrades',
      loadMoreItemTransactions: 'loadMoreItemTransactions',
      connectToInteractiveBrokers: 'connectToInteractiveBrokers',
      disconnectFromInteractiveBrokers: 'disconnectFromInteractiveBrokers',
      getBrokerPositionsFromInteractiveBrokers : 'getBrokerPositionsFromInteractiveBrokers',
      changeTaxLossHarvestTrades: 'changeTaxLossHarvestTrades',
      changeCountry: 'changeCountry',
      
    }),
    getOrderTypes (brokerName) {
      if(!this.userSettings) return [];
      //
      const broker = this.userSettings.options.brokers.find(x => x.brokerName === brokerName);
      if(!broker) return [];
      return broker.orderTypes;
    },
    getFirstOrderType (brokerName) {
      if(!this.userSettings) return null;
      const broker = this.userSettings.options.brokers.find(x => x.brokerName === brokerName);
      if(!broker || broker.orderTypes.length <= 0) return null;
      return broker.defaultOrderType;
    },
    onBrokerChanged(brokerName) {
      this.userSettings.defaultOrderType = this.getFirstOrderType(brokerName);
      this.onOrderTypeChanged(brokerName, this.userSettings.defaultOrderType);
    },
    onOrderTypeChanged(brokerName, orderTypeName) {
     
      this.userSettings.defaultOrderSettings = '';
      const broker = this.userSettings.options.brokers.find(x => x.brokerName === brokerName);
      if(!broker || broker.orderTypes.length <= 0) return null;
      
      const orderType = broker.orderTypes.find(x => x.orderType === orderTypeName);
      if(!orderType) return;
      
      if(orderType.settings && orderType.settings.length > 0) {
        orderType.settings.forEach((s, index) => {
          this.userSettings.defaultOrderSettings += `${s.name}=${s.value}` + (index < orderType.settings.length -1 ? ',' : '');
        });
      }
    },
    async onSaveExecutionSettings () {
      var dto = JSON.parse(JSON.stringify(this.executionSettings));
       
      dto.forEach(x => {
         
          x.defaultOrderType = x.orderTypes.find(o => o.orderType === x.defaultOrderType);

      });
      
      this.executionSettingsSaving = true;
      await this.saveExecutionSettings({value: dto});
      this.executionSettingsSaving = false;
    },
    async onSaveUserSettings () {
      const value = {
        ...this.userSettings,
        options: null,
        AnnualFixFee: this.userSettings.annualFixFee
      }
      this.userSettingsSaving = true;
      await this.saveUserSettings({value});
      this.userSettingsSaving = false;
    },
    async onConnect() {
      if(this.connecting) return;

      this.connecting = true;
      try{
        await this.connectToInteractiveBrokers();
      }catch(e) {
        console.error(e);
      }finally{
        this.connecting = false;
      }
    },
    async onDisconnect() {
      if(this.connecting) return;

      this.connecting = true;
      try{
        await this.disconnectFromInteractiveBrokers();
      }catch(e) {
        console.error(e);
      }finally{
        this.connecting = false;
      }
    },
    async onGetBrokerPositions() {
      if(this.gettingBrokerPositions) return;

      this.gettingBrokerPositions = true;
      try{
        await this.getBrokerPositionsFromInteractiveBrokers();
      }catch(e) {
        console.error(e);
      }finally{
        this.gettingBrokerPositions = false;
      }
    },
    async init() {
     
    },
    switchToExecution() {
      this.activeTab = 7;
    },
      // eslint-disable-next-line no-unused-vars
    middleware(event, el) {
      if (event.target.classList)
        return !event.target.classList.contains("toggle-right");
    },
   
    toggleCloneForm(index) {
      const id = index;
      if(this.cloneOrdeFormId === id) {
        this.cloneOrdeFormId = null;
      } else {
        this.cloneOrdeFormId = id;
      }
    },
    hideCloneForm() {
      this.cloneOrdeFormId = null;
    },
    cloneOrder(sourceIndex, { checkedOnly, withTheSameType}) {

      const sourceRow = this.executionSettings[sourceIndex];
      const orderType = sourceRow.defaultOrderType;
      const orderTypeSettings = sourceRow.orderTypes.find(x => x.orderType === orderType);

      let targetSettings = [...this.executionSettings];
      if(checkedOnly) {
        targetSettings = targetSettings.filter(x => x.selected);
      }

      if(withTheSameType) {
        targetSettings = targetSettings.filter(x => x.defaultOrderType === orderType);
      }
      
      targetSettings.forEach(row => {
        if(row === sourceRow) return;
        row.defaultOrderType = orderType;
        const otSettings = row.orderTypes.find(x => x.orderType === orderType);
        if(otSettings) {
          otSettings.orderParameters = orderTypeSettings.orderParameters;
        }
      });

      this.hideCloneForm();
    },
    toggleParametersForm(index) {
      const id = index;
      if(this.parametersFormId === id) {
        this.parametersFormId = null;
      } else {
        this.parametersData.ticksAway = null;
        this.parametersData.price = null;
        this.parametersData.time = null;
        this.parametersFormId = id;
       this.$nextTick(() =>{
          if(this.$refs.paramInput) {
            if(Array.isArray(this.$refs.paramInput)) {
              if(this.$refs.paramInput.length > 0) {
                this.$refs.paramInput[0].focus();
              }
            } else {
              this.$refs.paramInput.focus();
            }
          }
       });
      }
    },
    saveParametersForm (sourceIndex, ot) {

      const sourceRow = this.executionSettings[sourceIndex];
      const sourceOrderTypeSettings = sourceRow.orderTypes.find(x => x.orderType === ot);

      this.parametersFormId = null;
    
      let v = '';
      if(ot === 'Limit') {
        v = "price=";
        
        if(this.parametersData.price  !== null && this.parametersData.price !== undefined && this.parametersData.price !== "") {
          v += this.parametersData.price;
        }
      
      } else if(ot === 'Best Side Limit') {
        v = "TicksAway=";
        if(this.parametersData.ticksAway  !== null && this.parametersData.ticksAway !== undefined && this.parametersData.ticksAway !== "") {
          v += this.parametersData.ticksAway;
        }
        
      } else if(ot === 'TWAP') {
        v = "time=";
        if(this.parametersData.time  !== null && this.parametersData.time !== undefined && this.parametersData.time !== "") {
          v += this.parametersData.time;
        }
      }
      sourceOrderTypeSettings.orderParameters = v;
    },
    hideParamtersForm() {
    
      this.parametersFormId = null;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" :is-busy="loading"/>
    <div class="card cardc8">
      <div class="card-content m-3">
        <b-tabs  content-class="p-3"  v-model='activeTab'>
          <b-tab active>
            <template v-slot:title>
              <span class="">Index Summary</span>
            </template>
            <div class="row mb-4">
               <div class="col-xl-8 pt-2">
                 <highcharts 
                  v-if="chartOptions" 
                  :constructorType="'stockChart'" 
                  class="hc" 
                  :options="chartOptions" 
                  ref="chart"
                >
                </highcharts> 
               </div>
               <div class="col-xl-4  pt-2">
                 <select class="form-control" v-model="currencyName">
                   <option value="USD">USD</option>
                   <option value="CNY">CNY</option>
                 </select>
                 <dl class="mt-3">
                   <dt v-if="item.totalReturn">Total Return</dt>
                   <dd v-if="item.totalReturn"><b class="text-warning font-size-20">{{item.totalReturn}}</b></dd>
                   <dt v-if="item.ytd">YTD</dt>
                   <dd v-if="item.ytd"><b class="text-warning font-size-20">{{item.ytd}}</b></dd>
                   <dt v-if="item.mtd">MTD</dt>
                   <dd v-if="item.mtd"><b class="text-warning font-size-20">{{item.mtd}}</b></dd>
                   <dt v-if="item.dtd">DTD</dt>
                   <dd v-if="item.dtd"><b class="text-warning font-size-20">{{item.dtd}}</b></dd>
                 </dl>
               </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="">Weights</span>
            </template>
            <div class="table-responsive">
              <table class="table table-centered nowrap">
                <thead>
                  <tr>
                    <th>
                      Product
                    </th>
                    <th>
                      Ticker
                    </th>
                    <th>
                      Weight (where 100 is equivalent to 100%)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="weights.length === 0">
                    <td colspan="100">
                      <span class="text-muted">No records found...</span>
                    </td>
                  </tr>
                  <tr v-for="(w, $index) in weights" :key="$index">
                    <td>
                      {{w.productName}}
                    </td>
                     <td>
                      {{w.ticker}}
                    </td>
                     <td>
                      {{w.weight}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="">ReBalance</span>
            </template>
            <div class="form-group d-inline-block mr-4">
              <input type="checkbox" v-model="reBalanceCloseoutTrades" id="reBalanceCloseoutTrades"/>
              <label for="reBalanceCloseoutTrades" class="ml-2">Show Closeout Trades</label>
            </div>
            <!-- <div class="form-group d-inline-block mr-4">
              <input type="checkbox" v-model="taxLossHarvestTrades" id="taxLossHarvestTrades"/>
              <label for="taxLossHarvestTrades" class="ml-2">Show Loss Harvest Trades</label>
            </div>
            <div class="d-inline-block" >
              <select class="form-control" v-model="country">
                <option value="US">US</option>
              </select>
            </div> -->
            <div class="table-responsive">
              <table class="table table-centered nowrap">
                <thead>
                  <tr>
                    <th>
                      Product
                    </th>
                    <th>
                      Ticker
                    </th>
                    <th>
                      Current Size
                    </th>
                    <th>
                      Difference
                    </th>
                    <th>
                      Proposed Size
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="reBalance.length === 0">
                    <td colspan="100">
                      <span class="text-muted">No records found...</span>
                    </td>
                  </tr>
                  <tr v-for="(w, $index) in reBalance" :key="$index">
                    <td>
                      {{w.productName}}
                    </td>
                     <td>
                      {{w.ticker}}
                    </td>
                     <td>
                      {{w.currentSize}}
                    </td>
                     <td>
                      {{w.difference}}
                    </td>
                     <td>
                      {{w.proposedSize}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
          <b-tab  @click="loadPositions">
            <template v-slot:title>
              <span class="">Positions</span>
            </template>

             <div class="mb-4 mt-2">
              <div class="row">
                <div class="col-6">
                   
                </div>
                <div class="col-6 text-right">
                   <button class="btn btn-primary mr-2" v-if="!connected" :disabled="connecting" @click="onConnect">
                    <i class="fa fa-spin fa-spinner" v-if="connecting"></i>
                    Connect
                  </button>
                  <button class="btn btn-secondary mr-4" v-if="connected" :disabled="connecting"  @click="onDisconnect">
                    <i class="fa fa-spin fa-spinner" v-if="connecting"></i>
                    Diconnect
                  </button>
                  <button class="btn btn-secondary mr-2" v-if="connected" :disabled="gettingBrokerPositions"  @click="onGetBrokerPositions">
                    <i class="fa fa-spin fa-spinner" v-if="gettingBrokerPositions"></i>
                    Get Broker Positions
                  </button>
                </div>
              </div>
            
            </div>
           
            <div class="table-responsive">
              <table class="table table-centered nowrap">
                <thead>
                  <tr>
                    <th>
                      Product
                    </th>
                    <th>
                      Ticker
                    </th>
                    <th>
                      Size
                    </th>
                    <th>
                      Broker Position
                    </th>
                    <th>
                      PL, %
                    </th>
                    <th>
                      Warning
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="positions.length === 0">
                    <td colspan="100">
                      <span class="text-muted">No records found...</span>
                    </td>
                  </tr>
                  <tr v-for="(w, $index) in positions" :key="$index">
                    <td>
                      {{w.productName}}
                    </td>
                     <td>
                      {{w.ticker}}
                    </td>
                     <td>
                      {{w.size}}
                    </td>
                     <td>
                      {{w.brokerPosition}}
                    </td>
                     <td>
                      {{w.pl}}
                    </td>
                    <td>
                      {{w.warning}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
          <b-tab @click="loadTransactions">
            <template v-slot:title>
              <span class="">Transactions</span>
            </template>
           
            <div class="table-responsive">
              <table class="table table-centered nowrap">
                <thead>
                  <tr>
                    <th>
                      Product
                    </th>
                    <th>
                      Ticker
                    </th>
                    <th>
                      Direction
                    </th>
                    <th>
                      Size
                    </th>
                    <th>
                      Price
                    </th>
                    <th>
                      Timestamp
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="transactions.items.length === 0">
                    <td colspan="100">
                      <span class="text-muted">No records found...</span>
                    </td>
                  </tr>
                  <tr v-for="(w, $index) in transactions.items" :key="$index">
                    <td>
                      {{w.productName}}
                    </td>
                     <td>
                      {{w.ticker}}
                    </td>
                     <td>
                      {{w.direction}}
                    </td>
                     <td>
                      {{w.size}}
                    </td>
                     <td>
                      {{w.price}}
                    </td>
                    <td>
                      {{w.timestamp}}
                    </td>
                  </tr>
                   <tr v-if="transactions.items.length < transactions.total">
                      <td colspan="100" class="text-center">
                        <button class="btn btn-secondary" @click="loadMoreItemTransactions">
                          Show more
                        </button>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
          <b-tab @click="loadExecutionSettings">
            <template v-slot:title>
              <span class="">Execution Settings</span>
            </template>

            <div v-if="executionSettings">
              <div>
              <table class="table table-centered nowrap">
                <thead>
                  <tr>
                    <th style="width:40px; min-width:40px; max-width:40px;" class="text-center">
                      <input type="checkbox" class=" mt-1" v-model="allExecutionSettingsSelected"/>
                    </th>
                    <th>
                      Product
                    </th>
                    <th>
                      Ticker
                    </th>
                    <th>
                      Order Type
                    </th>
                    <th>
                      Order Parameters
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="executionSettings.length === 0">
                    <td colspan="100">
                      <span class="text-muted">No records found...</span>
                    </td>
                  </tr>
                  <tr v-for="(w, $index) in executionSettings" :key="$index">
                    <th style="width:40px; min-width:40px; max-width:40px;" class="text-center">
                      <input type="checkbox" class=" mt-1" v-model="w.selected"/>
                    </th>
                    <td>
                      {{w.displayName || w.productName}}
                    </td>
                     <td>
                      {{w.ticker}}
                    </td>
                    <td>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <button type="button" class="btn btn-secondary btn-sm px-3" @click="toggleCloneForm($index)">
                            <i class="fa fa-clone "></i>
                          </button>
                          
                          <div 
                            class="dropdown-menu dropdown-menu-end border show" 
                            v-click-outside="cloneOrderFormOutsideClickConfig"
                            v-if="cloneOrdeFormId === $index"
                          >
                            <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;" 
                              @click="cloneOrder($index, {checkedOnly: true, withTheSameType: false})"
                            >
                              Clone to selected products
                            </a>
                            <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;"
                              @click="cloneOrder($index, {checkedOnly: false, withTheSameType: false})"
                            >
                              Clone to all products
                            </a>
                          
                            <div class="dropdown-divider" v-if="w.defaultOrderType  !== 'Market'"></div>
                            <h6 class="dropdown-header" v-if="w.defaultOrderType  !== 'Market'">Only for the same order type</h6>
                            <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;"
                              v-if="w.defaultOrderType  !== 'Market'"
                              @click="cloneOrder($index, {checkedOnly: true, withTheSameType: true})"
                            >
                              Clone to selected products
                            </a>
                            <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;"
                              v-if="w.defaultOrderType  !== 'Market'"
                              @click="cloneOrder($index, {checkedOnly: false, withTheSameType: true})"
                            >
                              Clone to all products
                            </a>
                          </div>
                        </div>
                        <select class="form-control input-sm" v-model="w.defaultOrderType">
                          <option v-for="(ot,ii) in w.orderTypes" :key="$index + '-' + ii" :value="ot.orderType">{{ot.orderType}}</option>
                        </select>
                      </div>
                      
                     
                    </td>
                   
                    <td>
                      <b v-if="w.defaultOrderType  === 'Market'">N/A</b>
                      <div v-else>
                        <div class="input-group">
                          
                          <input type="text" class="form-control input-sm"
                            :value="w.orderTypes.find(x => x.orderType === w.defaultOrderType).orderParameters"  
                            readonly
                          />
                          <div class="input-group-append">
                            <div class="btn-group">
                              <button type="button" class="btn btn-secondary btn-sm px-3" @click="toggleParametersForm($index)">
                                <i class="fa fa-pen "></i>
                              </button>
                              <div 
                                class="dropdown-menu dropdown-menu-end border show" 
                                v-click-outside="parametersFormOutsideClickConfig"
                                v-if="parametersFormId === $index"
                              >
                                <div class="px-3 pt-2">
                                  <div class="form-group" v-if="w.defaultOrderType  === 'Limit'">
                                    <input 
                                      ref="paramInput" 
                                      class="form-control input-sm" 
                                      autofocus 
                                      placeholder="Price" 
                                      type="number" 
                                      v-model.number="parametersData.price"
                                      @keyup.enter="saveParametersForm($index, 'Limit')"
                                    />
                                  </div>
                                  <div class="form-group" v-if="w.defaultOrderType  === 'Best Side Limit'">
                                    <input 
                                      ref="paramInput" 
                                      class="form-control input-sm" 
                                      autofocus 
                                      placeholder="Ticks Away" 
                                      type="number" 
                                      v-model.number="parametersData.ticksAway"
                                      @keyup.enter="saveParametersForm($index, 'Best Side Limit')"
                                    />
                                  </div>
                                  <div class="form-group" v-if="w.defaultOrderType  === 'TWAP'">
                                    <input 
                                      ref="paramInput" 
                                      class="form-control input-sm" 
                                      autofocus 
                                      placeholder="Time" 
                                      type="time" 
                                      v-model="parametersData.time"
                                      @keyup.enter="saveParametersForm($index, 'TWAP')"
                                    />
                                  </div>
                                </div>
                                <hr/>
                                <div class="px-3 pb-2">
                                  <button class="btn btn-primary btn-block" type="button" 
                                  @click="saveParametersForm($index, w.defaultOrderType)">Save</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

              <button type="button" class=" float-right btn btn-primary mt-4" :disabled="executionSettingsSaving" @click="onSaveExecutionSettings">
                <i class="fa fa-save  mr-1" v-if="!executionSettingsSaving"></i>
                <i class="fa fa-spin fa-spinner mr-1" v-if="executionSettingsSaving"></i>
                Save
              </button>
            </div>
          </b-tab>
          <b-tab @click="loadUserSettings">
            <template v-slot:title>
              <span class="">User Settings</span>
            </template>

            <div v-if="userSettings">
              <div class="row">
                <label class="mb-1 mt-3 font-weight-medium col-md-3">Gearing</label>
                <input class="form-control col-md-9" v-model="userSettings.gearing"/>
              </div>
            
              <div class="row">
                <label class="mb-1 mt-3 font-weight-medium col-md-3">AUS</label>
                <input class="form-control  col-md-9" v-model.number="userSettings.aus"/>
              </div>
              <div class="row">
                <label class="mb-1 mt-3 font-weight-medium col-md-3">AUS Currency</label>
                <input class="form-control  col-md-9" v-model="userSettings.ausCurrency"/>
              </div>
              <div class="row">
                <label class="mb-1 mt-3 font-weight-medium col-md-3">Start Date</label>
                <input class="form-control  col-md-9" v-model="userSettings.startDate"/>
              </div>
              <div class="row">
                <label class="mb-1 mt-3 font-weight-medium col-md-3">Annual Fix Fee</label>
                <input class="form-control  col-md-9" v-model.number="userSettings.annualFixFee" readonly disabled/>
              </div>
              <div class="row">
                <label class="mb-1 mt-3 font-weight-medium col-md-3">Annual Performance Fee</label>
                <input class="form-control  col-md-9" v-model.number="userSettings.annualPerformanceFee" readonly disabled/>
              </div>
              
              <div class="row">
                <label class="mb-1 mt-3 font-weight-medium col-md-3">Broker</label>
                <select class="form-control  col-md-9" v-model="userSettings.broker" @change="($event) => onBrokerChanged($event.target.value) ">
                  <option v-for="(b,indexus) in userSettings.options.brokers" :key="indexus" :value="b.brokerName">
                    {{b.brokerName}}
                  </option>
                </select>
              </div>

              <div class="row">
                <label class="mb-1 mt-3 font-weight-medium col-md-3">Account</label>
                <input class="form-control  col-md-9" v-model="userSettings.account"/>
              </div>
              
              <div class="row">
                <label class="mb-1 mt-3 font-weight-medium col-md-3">Default Order Type</label>
                <select class="form-control  col-md-9" v-model="userSettings.defaultOrderType" @change="($event) => onOrderTypeChanged(userSettings.broker, $event.target.value) ">
                  <option v-for="(ot,indexot) in getOrderTypes(userSettings.broker)" :key="indexot" :value="ot.orderType">
                    {{ot.orderType}}
                  </option>
                </select>
              </div>

              <div class="row">
                <label class="mb-1 mt-3 font-weight-medium col-md-3">Default Order Settings</label>
                <input class="form-control  col-md-9" v-model="userSettings.defaultOrderSettings"/>
              </div>

              <div class="row">
                <label class="mb-1 mt-3 font-weight-medium col-md-3">User Name</label>
                <input class="form-control  col-md-9" v-model="userSettings.userName"/>
              </div>
              <div class="row">
                <label class="mb-1 mt-3 font-weight-medium col-md-3">Password</label>
                <input class="form-control  col-md-9" v-model="userSettings.password"/>
              </div>
              <div class="row">
                <label class="mb-1 mt-3 font-weight-medium col-md-3">Email</label>
                <input class="form-control  col-md-9" v-model="userSettings.email"/>
              </div>

              <button type="button" class=" float-right btn btn-primary mt-4" :disabled="userSettingsSaving" @click="onSaveUserSettings">
                <i class="fa fa-save  mr-1" v-if="!userSettingsSaving"></i>
                <i class="fa fa-spin fa-spinner mr-1" v-if="userSettingsSaving"></i>
                Save
              </button>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="">Execution</span>
            </template>

            <runtime :id="id"></runtime>
          </b-tab>
           <b-tab lazy>
            <template v-slot:title>
              <span class="">Tax loss harvest</span>
            </template>

            <tax-loss-harvest :id="id" @execute="switchToExecution"></tax-loss-harvest>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </Layout>
</template>