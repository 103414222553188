<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment'
import { saveAs } from 'file-saver';
import { debounce } from "debounce"
export default {
  components: {
   
  },
  created() {
    this.init();
  },
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      initializing: true,
      ready: false,
      connecting: false,
      executing: false,
      txUploading: false,
      orderCancellation: false,
      gettingBrokerPositions: false,
      closeoutTrades: false,
      taxLossHarvest: false,
      parametersFormId: null,
      cloneOrderFormId: null,
      country: 'US',
      parametersFormOutsideClickConfig: {
        handler: this.hideParamtersForm,
        middleware: this.middleware,
        events: ["click"],
      },
      cloneOrderFormOutsideClickConfig: {
        handler: this.hideCloneForm,
        middleware: this.middleware,
        events: ["click"],
      },
    
      parametersData: {
        price: null,
        ticksAway: 0,
        time: null
      }
    };
  },
  computed:{
    ...mapGetters('execution', {
      orders: 'runtimeOrders',
      interactiveBrokers: 'interactiveBrokers',
      binance: 'binance',
      item: 'item',
      uploadTransactionsFromTime: 'uploadTransactionsFromTime'
    }),
     ...mapGetters('execution', {
    //  isBackendAlive: 'isBackendAlive'
    }),
    connected () {
      return this.interactiveBrokers.connected
      || this.binance.connected;
    },
    allOrdersSelected: {
      get() {
        const res = this.orders.find(x => !x.selected) ? false : true;
        return res ;
      },
      set(v) {
        if(v) {
          this.selectAllRuntimeOrders();
        } else {
          this.unselectAllRuntimeOrders();
        }
      }
    },
    brokerName () {
      const { userSettings }  = this.item;
      const { broker }  = userSettings || {};
      return broker
    }
  },
  watch: {
    brokerName: {
      handler: function (newValue, oldValue) {
        if(!this.ready) return;

        if(newValue != oldValue) {
        
          this.disposeBroker(oldValue);
          this.initBroker(newValue);
        }
      }
    },
    // isBackendAlive: {
    //   handler: function () {
    //    this.init();
    //   }
    // }
  },
  methods: {
    ...mapMutations('execution', {
      selectAllRuntimeOrders: 'SELECT_ALL_RUNTIME_ORDERS',
      unselectAllRuntimeOrders: 'UNSELECT_ALL_RUNTIME_ORDERS',
      setOrderSelected: 'SET_ORDER_SELECTED',
      setOrderType: 'SET_ORDER_TYPE',
      setOrderSize:'SET_ORDER_SIZE',
      setOrderParameters: 'SET_ORDER_PARAMETERS',
      setOrderPrice: 'SET_ORDER_PRICE',
      setItemId: 'SET_ITEM_ID',
      setUploadTransactionsFromTime: 'SET_UPLOAD_TRANSACTIONS_FROM_TIME',
     
    }),
    ...mapActions('execution', {
      initRuntime: 'initRuntime',

      initInteractiveBrokers: 'initInteractiveBrokers',
      connectToInteractiveBrokers: 'connectToInteractiveBrokers',
      disconnectFromInteractiveBrokers: 'disconnectFromInteractiveBrokers',
      executeOrderInteractiveBrokers: 'executeOrderInteractiveBrokers',
      updateInteractiveBrokersOrders: 'updateInteractiveBrokersOrders',
      uploadTransactionsFromInteractiveBrokers: 'uploadTransactionsFromInteractiveBrokers',
      cancellAllInteractiveBrokersOrders: 'cancellAllInteractiveBrokersOrders',
      connectToBinance: 'connectToBinance',
      disconnectFromBinance: 'disconnectFromBinance',
      changeCurrency: 'changeCurrency',
      getBrokerPositionsFromInteractiveBrokers : 'getBrokerPositionsFromInteractiveBrokers',
      paperTrade: 'paperTrade',
      loadUserSettings: 'loadUserSettings',
      cancelOrderInteractiveBrokers: 'cancelOrderInteractiveBrokers'
    }),
    onCloseoutTradesCheck() {
      if(this.closeoutTrades) {
        this.taxLossHarvest = false;
      }
      this.debounceGenerateOrders();
    },
    onTaxLossHarvestCheck() {
      if(this.taxLossHarvest) {
        this.closeoutTrades = false;
      }
      this.debounceGenerateOrders();
    },
    debounceGenerateOrders: debounce(function () {
      this.generateOrders();
    }, 600),
    async generateOrders() {
     this.initializing = true;
      try {
        await this.initRuntime( { 
          indexId: this.id, 
          refresh: true, 
          country: this.country,
          taxLossHarvest: this.taxLossHarvest, 
          closeout: this.closeoutTrades 
        });
        //this.title = '' + this.item.name;
      } catch(e) {
        console.log('init error', e);
      }
      this.initializing = false;      
    },
    async initBroker() {
      
      try{
        //if(brokerName == 'IB') {
          await this.initInteractiveBrokers();
        //}
      }catch(e) {
        console.error(e);
      }
    },
    async disposeBroker() {
      
      try {
        //if(brokerName == 'IB') {
          await this.disconnectFromInteractiveBrokers();
        // } else if(brokerName == 'Binance') {
        //   await this.disconnectFromBinance();
        // }
      }catch(e) {
        console.error(e);
      }
    },
    async onUploadTransactions() {
      this.txUploading = true;
      try{
        await this.uploadTransactionsFromInteractiveBrokers();
      }finally {
        this.txUploading = false;
      }
    
    },
     // eslint-disable-next-line no-unused-vars
    middleware(event, el) {
      if (event.target.classList)
        return !event.target.classList.contains("toggle-right");
    },
    async init() {
      await this.loadUserSettings();
      //if(this.isBackendAlive) {
        this.initializing = true;
        try {
          await this.initRuntime( { indexId: this.id, refresh: false });
          
          this.initBroker(this.brokerName);
        
        } catch(e) {
          console.error(e);
        }
       
        this.initializing = false;
        this.ready = true;
     // }
    },
    async onConnect() {
      if(this.connecting) return;

      this.connecting = true;
      this.txUploading = false;   
      try{
       // if(this.brokerName === 'IB') {
          await this.connectToInteractiveBrokers();
        //} else if(this.brokerName == 'Binance') {
         // await this.connectToBinance();
       // }
        
      }catch(e) {
        console.error(e);
      }finally{
        this.connecting = false;
      }
    },
    async onDisconnect() {
      if(this.connecting) return;

      this.connecting = true;
      try{
       // await this.disconnectFromBinance();
        await this.disconnectFromInteractiveBrokers();
      }catch(e) {
        console.error(e);
      }finally{
        this.connecting = false;
      }
    },
    async onGetBrokerPositions() {
      if(this.gettingBrokerPositions) return;

      this.gettingBrokerPositions = true;
      try{
        await this.getBrokerPositionsFromInteractiveBrokers();
      }catch(e) {
        console.error(e);
      }finally{
        this.gettingBrokerPositions = false;
      }
    },
    async onExecute() {
      if(this.executing) return;
      const selectedOrders = this.orders.filter(x => x.selected);
      this.executing = true;
      try{
        for(let i = 0; i < selectedOrders.length; i++) {
          const o = selectedOrders[i];
          let ticker = o.ticker;
          if ( ticker.includes(":") )
          {
            var nameList = ticker.split(":");
            ticker = nameList[0];
          }
          const instrumentType = o.instrumentType;
          let securityType = "FUT";

          if ((instrumentType == "Stock") || (instrumentType == "STK")) {
              securityType = "STK";
          }

          if ((instrumentType == "Future") || (instrumentType == "Futures") || (instrumentType == "FUT")) {
              securityType = "FUT";
          }

          if ((instrumentType == "Option") || (instrumentType == "OPT")) {

              securityType = "OPT";
          }

          let price = 0;
          let orderType = "MKT";
         
          if (o.orderType.toLowerCase() == 'limit') {
            orderType = "LMT";
            price =  o.price;
          } else if (o.orderType.toLowerCase() == 'twap') {
            orderType = "TWAP";
          }

          let maturityDate = null;
          if(securityType === 'FUT') {
            const expirationMomemt = moment(o.expirationDate);
            const expiratioDate = expirationMomemt.toDate();
            var expires = new Date(expiratioDate.getFullYear(), expiratioDate.getMonth(), 1);
            maturityDate = expires
          }

          const m = {
            account: o.account || o.Account,
            clOrderID: o.id,
            instrument: ticker,
            securityType,
            side: o.orderQuantity >= 0 ? 'Buy' : 'Sell',// o.orderSide.trim(),
            orderType,
            qty: Math.abs(o.orderQuantity),
            currency: o.currencyName,
            price,
            exchange: o.exchangeName,
            timeInForce: 'GTC',
            maturityDate,
            execInst: o.orderParameters,
            productName: o.productName,
            ticker
          };
          await this.executeOrderInteractiveBrokers(m);
        }
      }catch(e) {
        console.error(e);
      }finally{
        this.executing = false;
      }
      
     
    },
    getOrderTypes (brokerName) {
      if(!this.userSettings) return [];
      //
      const broker = this.userSettings.options.brokers.find(x => x.brokerName === brokerName);
      if(!broker) return [];
      return broker.orderTypes;
    },
    downloadOrders() {
      let text = '';
      this.orders.forEach(order => {
        let buyOrSell = "B";

        if (order.orderSide.toLowerCase() == "sell") {
          buyOrSell = "S";
        }
        text += order.ticker + ',';
        text += order.orderQuantity + ',';
        text += buyOrSell + '\n';
      });
      var blob = new Blob([text],
                { type: "text/plain;charset=utf-8" });
       saveAs(blob, "orders.csv");
    },
    async cancellAllOrders() {
      this.orderCancellation = true;
      try {
        await this.cancellAllInteractiveBrokersOrders();
      } finally {
        this.orderCancellation = false;
      }
    },
    
    toggleCloneForm(index) {
      if(this.cloneOrderFormId === index) {
        this.cloneOrderFormId = null;
      } else {
        this.cloneOrderFormId = index;
      }
    },
    hideCloneForm() {
      this.cloneOrderFormId = null;
    },
    cloneOrder(sourceIndex, { checkedOnly, withTheSameType}) {

      const sourceOrder = this.orders[sourceIndex];
      const origArray =[...this.orders];
      let targetOrders = [...this.orders];
      if(checkedOnly) {
        targetOrders = targetOrders.filter(x => x.selected);
      }

      const orderParameters = sourceOrder.orderParameters;

      if(withTheSameType) {
        targetOrders = targetOrders.filter(x => x.orderType === sourceOrder.orderType);
      }

      if(!withTheSameType) {
        targetOrders.forEach(x => {
          this.setOrderType({index: origArray.indexOf(x), orderType: sourceOrder.orderType})
        });
      }

      targetOrders.forEach(x => {
        this.setOrderParameters({index: origArray.indexOf(x), parameters: orderParameters})
      });

      this.hideCloneForm();
    },
    toggleParametersForm(index) {
      if(this.parametersFormId === index) {
        this.parametersFormId = null;
      } else {
        this.parametersData.ticksAway = null;
        this.parametersData.price = null;
        this.parametersData.time = null;
        this.parametersFormId = index;
       this.$nextTick(() =>{
          if(this.$refs.paramInput) {
            if(Array.isArray(this.$refs.paramInput)) {
              if(this.$refs.paramInput.length > 0) {
                this.$refs.paramInput[0].focus();
              }
            } else {
              this.$refs.paramInput.focus();
            }
          }
       });
      }
    },
    saveParametersForm (index) {
      this.parametersFormId = null;
      const order = this.orders[index];
      if(order.orderType === 'Limit') {
        let parameters = "price=";
        let v = null;
        if(this.parametersData.price  !== null && this.parametersData.price !== undefined && this.parametersData.price !== "") {
          parameters += this.parametersData.price;
          v = this.parametersData.price;
        }
        this.setOrderParameters({index: index, parameters: parameters});
        this.setOrderPrice({index: index, price: v})
      } else if(order.orderType === 'Best Side Limit') {
        let parameters = "TicksAway=";
        if(this.parametersData.ticksAway  !== null && this.parametersData.ticksAway !== undefined && this.parametersData.ticksAway !== "") {
          parameters += this.parametersData.ticksAway;
        }
        this.setOrderParameters({index: index, parameters: parameters});
      } else if(order.orderType === 'TWAP') {
        let parameters = "time=";
        if(this.parametersData.time  !== null && this.parametersData.time !== undefined && this.parametersData.time !== "") {
          parameters += this.parametersData.time;
        }
        this.setOrderParameters({index: index, parameters: parameters});
      }
    },
    hideParamtersForm() {
    
      this.parametersFormId = null;
    }
  }
}
</script>

<template>
  <div>
    <div v-if="initializing">
      Loading...
    </div>
    <div v-if="!initializing">
      <div class="mb-4 mt-2">
        <div class="row">
          <div class="col-8">
            <button class="btn btn-primary mr-2" v-if="!connected" :disabled="connecting" @click="onConnect">
              <i class="fa fa-spin fa-spinner" v-if="connecting"></i>
              Connect
            </button>
           
            <button class="btn btn-primary mr-4" v-if="connected" :disabled="executing" @click="onExecute">
              <i class="fa fa-spin fa-spinner" v-if="executing"></i>
              Execute
            </button>

             <button class="btn btn-warning mr-4" v-if="connected" :disabled="orderCancellation" @click="cancellAllOrders">
                <i class="fa fa-spin fa-spinner" v-if="orderCancellation"></i>
                Cancell All
              </button>

              <div style="display: inline-block;"  v-if="connected">
                <div class="input-group" >
                  <input type="time" class="form-control" style="width:120px" 
                    :value="uploadTransactionsFromTime"
                    @input="event => setUploadTransactionsFromTime(event.target.value)"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-secondary mr-2" :disabled="txUploading"  @click="$nextTick(() => onUploadTransactions())">
                      <i class="fa fa-spin fa-spinner" v-if="connected && txUploading"></i>
                      Upload Transactions
                    </button>
                  </div>
                </div>
              </div>

            <button class="btn btn-secondary mr-2" v-if="connected" :disabled="connecting"  @click="onDisconnect">
              <i class="fa fa-spin fa-spinner" v-if="connecting"></i>
              Diconnect
            </button>

            <button class="btn btn-secondary me-2 mr-2"  @click="generateOrders">Generate Orders</button>

          </div>
          <div class="col-4 text-right">
            <button class="btn btn-light mr-2"  @click="paperTrade">Paper Trade</button>
            <button class="btn btn-light mr-2" @click="downloadOrders">Download As File</button>
            <!-- <button class="btn btn-light">Send E-mail</button> -->
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <div class="d-inline-block mr-4">
              <input type="checkbox" v-model="closeoutTrades" @change="onCloseoutTradesCheck"/>
              <label for="reBalanceCloseoutTrades" class="ml-2">Closeout Trades</label>
            </div>
<!-- 
            <div class="d-inline-block  mr-4">
              <input type="checkbox" v-model="taxLossHarvest" @change="onTaxLossHarvestCheck"/>
              <label for="reBalanceCloseoutTrades" class="ml-2">Tax Loss Harvest Trades</label>
            </div>
            <div class="d-inline-block" >
              <select class="form-control" v-model="country">
                <option value="US">US</option>
              </select>
            </div> -->
          </div>
        </div>
       
      </div>
      <div class="">
        <table class="table table-centered nowrap">
          <thead>
            <tr>
              <th style="width:40px; min-width:40px; max-width:40px;" class="text-center">
                <input type="checkbox" class=" mt-1" v-model="allOrdersSelected"/>
              </th>
              <th>
                Product
              </th>
              <th>
                Ticker
              </th>
              <th>
                Order Type
              </th>
              <th>
                Parameters
              </th>
               <th>
                Direction
              </th> 
              <th>
                Size
              </th>
               <!-- <th>
                Price
              </th> -->
              <th>
                Status
              </th>
               <!-- <th>
                Bid
              </th>
              <th>
                Offer
              </th> -->
              <th>
                Filled
              </th>
              <th>
                Remaining
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="orders.length === 0">
              <td colspan="100">
                No orders found...
              </td>
            </tr>
            <tr v-for="(order, orderIndex) in orders" :key="orderIndex">
              <td style="width:40px; min-width:40px; max-width:40px;">
                <input type="checkbox" class=" mt-1" :checked="order.selected" @change="e => setOrderSelected({index: orderIndex, selected: !order.selected})"/>
              </td>
              <td>
                {{order.displayName || order.productName}}
              </td>
              <td>
                {{order.ticker}}
              </td>
              <td>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="btn-group">
                      <button type="button" class="btn btn-secondary btn-sm px-3" @click="toggleCloneForm(orderIndex)">
                        <i class="fa fa-clone "></i>
                      </button>
                      <div 
                        class="dropdown-menu dropdown-menu-end border show" 
                        v-click-outside="cloneOrderFormOutsideClickConfig"
                        v-if="cloneOrderFormId === orderIndex"
                      >
                        <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;" 
                          @click="cloneOrder(orderIndex, {checkedOnly: true, withTheSameType: false})"
                        >
                          Clone to selected orders
                        </a>
                         <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;"
                          @click="cloneOrder(orderIndex, {checkedOnly: false, withTheSameType: false})"
                         >
                           Clone to all orders
                        </a>
                       
                        <div class="dropdown-divider"  v-if="order.orderType !== 'Market'"></div>
                        <h6 class="dropdown-header"  v-if="order.orderType !== 'Market'">Only for the same order type</h6>
                        <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;"
                          v-if="order.orderType !== 'Market'"
                          @click="cloneOrder(orderIndex, {checkedOnly: true, withTheSameType: true})"
                        >
                          Clone to selected orders
                        </a>
                         <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;"
                          v-if="order.orderType !== 'Market'"
                          @click="cloneOrder(orderIndex, {checkedOnly: false, withTheSameType: true})"
                         >
                           Clone to all orders
                        </a>
                      </div>
                    </div>
                  </div>
                  <select class="form-control input-sm" :value="order.orderType" @change="event => setOrderType({index: orderIndex, orderType: event.target.value})">
                    <option v-for="(os, osIndex) in order.executionSettings.orderTypes" :key="osIndex" :value="os.orderType">
                    {{os.orderType}}
                    </option>
                  </select>
                </div>
              </td>
              <td>
                <b  v-if="order.orderType === 'Market'">N/A</b>
                <div class="input-group" v-else>
                  
                  <input type="text" :value="order.orderParameters" 
                    class="form-control input-sm"
                    readonly
                  />
                  <div class="input-group-append" v-if="order.orderType !== 'Market'">
                    <div class="btn-group">
                      <button type="button" class="btn btn-secondary btn-sm px-3" @click="toggleParametersForm(orderIndex)">
                        <i class="fa fa-pen "></i>
                      </button>
                      <div 
                        class="dropdown-menu dropdown-menu-end border show" 
                        v-click-outside="parametersFormOutsideClickConfig"
                        v-if=" parametersFormId === orderIndex"
                      >
                        <div class="px-3 pt-2">
                          <div class="form-group" v-if="order.orderType === 'Limit'">
                            <input 
                              ref="paramInput" 
                              class="form-control input-sm" 
                              autofocus 
                              placeholder="Price" 
                              type="number" 
                              v-model.number="parametersData.price"
                              @keyup.enter="saveParametersForm(orderIndex)"
                            />
                          </div>
                          <div class="form-group" v-if="order.orderType === 'Best Side Limit'">
                            <input 
                              ref="paramInput" 
                              class="form-control input-sm" 
                              autofocus 
                              placeholder="Ticks Away" 
                              type="number" 
                              v-model.number="parametersData.ticksAway"
                              @keyup.enter="saveParametersForm(orderIndex)"
                            />
                          </div>
                          <div class="form-group" v-if="order.orderType === 'TWAP'">
                            <input 
                              ref="paramInput" 
                              class="form-control input-sm" 
                              autofocus 
                              placeholder="Time" 
                              type="time" 
                              v-model="parametersData.time"
                              @keyup.enter="saveParametersForm(orderIndex)"
                            />
                          </div>
                        </div>
                        <hr/>
                        <div class="px-3 pb-2">
                          <button class="btn btn-primary btn-block" type="button" 
                          @click="saveParametersForm(orderIndex)">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
              </td>
               <td>
                <span v-if="order.orderQuantity > 0">Buy</span>
                <span v-if="order.orderQuantity < 0">Sell</span>
              </td>
              <td>
                <input type="number" :value="order.orderQuantity" class="form-control input-sm" @input="event => setOrderSize({index: orderIndex, size: event.target.value})"/>
              </td>
             
              <td>
                <span class="badge badge-success" v-if="order.orderStatus.toLowerCase() === 'filled'">
                  {{order.orderStatus}}
                </span>
                <span class="badge badge-danger" v-if="order.orderStatus.toLowerCase() === 'rejected'">
                  {{order.orderStatus}}
                </span>
                <span class="badge badge-warning" v-if="order.orderStatus.toLowerCase() === 'cancelled'">
                  {{order.orderStatus}}
                </span>
                <span v-if="order.orderStatus.toLowerCase() !== 'cancelled' && order.orderStatus.toLowerCase() !== 'rejected' && order.orderStatus.toLowerCase() !== 'filled'">
                  {{order.orderStatus}}
                  <a href="javascript:void(0)" v-if="!order.processing && order.orderStatus.toLowerCase() !== 'unknown'" class="text-danger" @click="cancelOrderInteractiveBrokers({clOrderID: order.id})">
                    <span >[cancel]</span>
                  </a>
                  <span v-if="order.processing"> <i class="fa fa-spin fa-spinner"></i></span>
                </span>
                 
              </td>
<!--               
              <td>
                {{(order.marketData || {}).bid || ''}}
              </td>
              <td>
                {{(order.marketData || {}).offer || ''}}
              </td> -->
              <td class="text-right">
                {{order.filled || 0}}
              </td>
              <td class="text-right">
                {{order.remaining  || 0}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
  </div>
</template>