<script>
import {  mapActions, mapGetters, mapMutations } from 'vuex';


export default {
  components: {
   
  },
  created() {
    this.loadTaxLossHarvest({ id: this.id});
  },
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      isBusy: false,
      initializing: false,
      expanded: {

      },
    };
  },
  computed:  {
    ...mapGetters('execution', {
      item: 'item'
    }),
    taxLoss() {
      return this.item?.taxLoss;
    },
    taxLossHarvestExecutionData() {
      return (this.taxLoss?.details ?? []).map(
        x => ({
          productName: x.name,
          size: x.size
        }));
    }
  },
  watch: {
   
  },
  methods: {
    ...mapMutations('execution', {
   
     
    }),
    ...mapActions('execution', {
      loadTaxLossHarvest: 'loadTaxLossHarvest',
      initRuntime: 'initRuntime',
    }),
    expand(index) {
      const o = {...this.expanded};
      o[index] = o[index] ? !o[index] : true;
      this.expanded = o;
    },
    async onExecute() {

      this.isBusy = true;
      try{
        await this.initRuntime( { 
          indexId: this.id, 
          taxLossHarvest: true, 
          taxLossHarvestData: this.taxLossHarvestExecutionData 
        });
      } finally {
        this.isBusy = false;
      }
      
      this.$emit('execute');
    }
  }
};
</script>

<template>
  <div v-if="taxLoss">
    
    <div>
      <div class="table-responsive">
        <table class="table  table-borderless nowrap">
          <thead>
            <tr>
              <th style="width:30px; max-width:30px"></th>
              <th class="col"></th>
              <th class="col-auto">Date</th>
              <th class="col-auto">Price</th>
              <th class="col-auto text-nowrap">Current Position</th>
              <th class="col-auto text-nowrap">Proposed Position</th>
              <th class="col-auto text-nowrap">Tax Loss</th>
              <th class="col-auto text-nowrap">Trades</th>
              <th class="col-auto text-nowrap">Size</th>
              <th class="col-auto text-nowrap">Price</th>
            </tr>
          </thead>
          <tbody v-if="taxLoss && taxLoss.details">
            <template v-for="(rec, $index) in taxLoss.details">
              <tr :key="$index" :class="{'border-top border-left border-right bg-light': expanded[$index]}">
                <td style="width:30px; max-width:30px">
                  <a  @click="expand($index)" v-if="rec.subItems.length > 0">
                    <b v-if="expanded[$index]"><i class="fa fa-chevron-up "></i></b>
                    <b v-if="!expanded[$index]"><i class="fa fa-chevron-down "></i></b>
                  </a>
                </td>
                <td class="col">{{rec.name}}</td>
                <td class="col-auto text-nowrap">{{rec.date | moment('L')}}</td>
                <td class="col-auto text-nowrap">{{(rec.price || 0).toFixed(2)}}</td>
                <td class="col-auto text-nowrap">{{rec.currentPosition}}</td>
                <td class="col-auto text-nowrap">{{rec.proposedPosition}}</td>
                <td class="col-auto text-nowrap">{{(rec.taxLoss || 0).toFixed(2)}}</td>
                <td class="col-auto text-nowrap">
                  <i class="mdi-check-bold mdi mr-2" v-if="rec.trade"></i>
                  <span>{{rec.direction}}</span>
                </td>
                <td class="col-auto text-nowrap">
                  <input type="number" class="form-control input-sm" style="min-width:100px" v-model.number="rec.size" />
                </td>
                <td class="col-auto text-nowrap">{{rec.price}}</td>
              </tr>
              <template v-if="expanded[$index]">
                <tr  v-for="(subRec, $sIndex) in rec.subItems" :key="$sIndex + 'c' + $index" class="border-left border-right bg-light" :class="{'border-bottom ': $sIndex == (rec.subItems.length - 1) && expanded[$index], }">
                  <td ></td>
                  <td></td>
                  <td class="col-auto text-nowrap">{{subRec.date | moment('L')}}</td>
                  <td class="col-auto text-nowrap">{{(subRec.price || 0).toFixed(2)}}</td>
                  <td class="col-auto text-nowrap">{{subRec.currentPosition}}</td>
                  <td class="col-auto text-nowrap">{{subRec.proposedPosition}}</td>
                  <td class="col-auto text-nowrap">{{(subRec.taxLoss || 0).toFixed(2)}}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </template>
            
          </tbody>
        </table>
      </div>
       <div class="mt-3 text-right">
       
          <button class="btn btn-primary mr-2 text-nowrap" @click="onExecute" :disabled="isBusy">
              <i class="fa fa-spin fa-spinner me-1" v-if="isBusy"></i>
              Execute
          </button>
         
        </div>
    </div>
  </div>
</template>